@font-face {
    font-family: 'SFCompactDisplay';
    src: url('/public/fonts/SF_Compact_Display/fonnts.com-SF_Compact_Display_Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'SFCompactDisplay';
    src: url('/public/fonts/SF_Compact_Display/fonnts.com-SF_Compact_Display_Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'SFCompactDisplay';
    src: url('/public/fonts/SF_Compact_Display/fonnts.com-SF_Compact_Display_Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'SFCompactDisplay';
    src: url('/public/fonts/SF_Compact_Display/fonnts.com-SF_Compact_Display_Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}
/*FONT HELVETICANEUE*/
@font-face {
    font-family: 'HelveticaNeueCyr';
    src: url('/public/fonts/HelveticaNeueCyr/HelveticaNeueCyr-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: 'HelveticaNeueCyr';
    src: url('/public/fonts/HelveticaNeueCyr/HelveticaNeueCyr-Roman.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'HelveticaNeueCyr';
    src: url('/public/fonts/HelveticaNeueCyr/HelveticaNeueCyr-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'HelveticaNeueCyr';
    src: url('/public/fonts/HelveticaNeueCyr/HelveticaNeueCyr-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}
