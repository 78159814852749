@import "./assets/fonts.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    :root {
        --background: 0 0% 100%;
        --foreground: 222.2 84% 4.9%;

        --card: 0 0% 100%;
        --card-foreground: 222.2 84% 4.9%;

        --popover: 0 0% 100%;
        --popover-foreground: 222.2 84% 4.9%;

        --primary: 222.2 47.4% 11.2%;
        --primary-foreground: 210 40% 98%;

        --secondary: 210 40% 96.1%;
        --secondary-foreground: 222.2 47.4% 11.2%;

        --muted: 210 40% 96.1%;
        --muted-foreground: 215.4 16.3% 46.9%;

        --accent: 210 40% 96.1%;
        --accent-foreground: 222.2 47.4% 11.2%;

        --destructive: 0 84.2% 60.2%;
        --destructive-foreground: 210 40% 98%;

        --border: 214.3 31.8% 91.4%;
        --input: 214.3 31.8% 91.4%;
        --ring: 222.2 84% 4.9%;

        --radius: 0.5rem;
    }

    .dark {
        --background: 222.2 84% 4.9%;
        --foreground: 210 40% 98%;

        --card: 222.2 84% 4.9%;
        --card-foreground: 210 40% 98%;

        --popover: 222.2 84% 4.9%;
        --popover-foreground: 210 40% 98%;

        --primary: 210 40% 98%;
        --primary-foreground: 222.2 47.4% 11.2%;

        --secondary: 217.2 32.6% 17.5%;
        --secondary-foreground: 210 40% 98%;

        --muted: 217.2 32.6% 17.5%;
        --muted-foreground: 215 20.2% 65.1%;

        --accent: 217.2 32.6% 17.5%;
        --accent-foreground: 210 40% 98%;

        --destructive: 0 62.8% 30.6%;
        --destructive-foreground: 210 40% 98%;

        --border: 217.2 32.6% 17.5%;
        --input: 217.2 32.6% 17.5%;
        --ring: 212.7 26.8% 83.9%;
    }
}

@layer base {
    * {
        @apply border-border;
    }

    body {
        @apply bg-background text-['#333333'];
    }
}

html, body {
    min-height: 100vh;
    font-family: HelveticaNeueCyr, sans-serif;
}

#rect {
    transform-origin: center;
    animation: wave 1s infinite linear alternate;
}

#rect:nth-child(1n) {
    animation-delay: 0.1s;
}

#rect:nth-child(2n) {
    animation-delay: 0.2s;
}

#rect:nth-child(3n) {
    animation-delay: 0.3s;
}

#rect:nth-child(4n) {
    animation-delay: 0.4s;
}

#rect:nth-child(5n) {
    animation-delay: 0.5s;
}

#rect:nth-child(6n) {
    animation-delay: 0.6s;
}

#rect:nth-child(7n) {
    animation-delay: 0.7s;
}

#rect:nth-child(8n) {
    animation-delay: 0.8s;
}

#rect:nth-child(9n) {
    animation-delay: 0.9s;
}

@keyframes wave {
    0% {
        transform: scale(1, 1);
    }
    100% {
        transform: scale(1, 0.2);
    }
}